import IconSchedules from '@/components/icons/IconSchedules'
import IconRations from '@/components/icons/IconRations'
import IconStocks from '@/components/icons/IconStocks'
import IconClock from '@/components/icons/IconClock'
import IconReports from '@/components/icons/IconReports'
import IconHandbook from '@/components/icons/IconHandbook'
import roles from '@/util/roles'

export default {
  components: {
    IconSchedules,
    IconRations,
    IconStocks,
    IconClock,
    IconReports,
    IconHandbook
  },

  data: () => ({
    options: [
      {
        id: 0,
        title: 'layouts.sidebar.planning',
        icon: 'IconSchedules',
        path: '/farm/planning',
        allow: [roles.admin, roles.support, roles.consultant, roles.customer, roles.director, roles.technologist]
      },
      {
        id: 1,
        title: 'layouts.sidebar.rations',
        icon: 'IconRations',
        path: '/farm/rations',
        allow: [roles.admin, roles.support, roles.consultant, roles.customer, roles.director, roles.technologist]
      },
      {
        id: 3,
        title: 'layouts.sidebar.storage',
        icon: 'IconStocks',
        path: '/farm/storage',
        allow: [roles.admin, roles.support, roles.consultant, roles.customer, roles.director, roles.technologist, roles.accounter]
      },
      {
        id: 5,
        title: 'layouts.sidebar.reports',
        icon: 'IconReports',
        path: '/farm/reports',
        allow: [roles.admin, roles.support, roles.consultant, roles.customer, roles.director, roles.technologist, roles.accounter]
      },
      {
        id: 6,
        title: 'layouts.sidebar.settings',
        icon: 'IconHandbook',
        path: '/farm/settings',
        allow: [roles.admin, roles.support, roles.consultant, roles.customer, roles.director, roles.technologist]
      }
    ]
  })
}
