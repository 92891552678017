<template lang="pug">
  li(:class="{ 'sidebar__item--active': active }").sidebar__item
    router-link(:to="path").sidebar__link
      icon-base(width="25" height="25" icon-name="icon").sidebar__link-icon
        slot(name="icon")
      span.sidebar__link-text {{ title | translate }}

</template>

<script>
import IconBase from '@/components/icons/IconBase.vue'

export default {
  name: 'SidebarItem',

  components: {
    IconBase
  },

  props: {
    title: String,
    path: String
  },

  computed: {
    active () {
      return this.$route.path.includes(this.path)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
