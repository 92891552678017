<template lang="pug">
  div
    .sidebar-layout__toolbar.sidebar-layout__toolbar--left
      h2.sidebar__title {{ sidebarTitle | translate }}
    .sidebar(:class="classes")
      v-card(:elevated="elevated")
        ul.sidebar__list
          sidebar-item(
            v-for="item in getSidebarItems"
            :key="item.id"
            :title="item.title"
            :path="item.path")
            component(
              slot="icon"
              :is="item.icon")
</template>

<script>
import { mapGetters } from 'vuex'
import SidebarItem from './SidebarItem'
import menuMixin from '@/mixins/menu.mixin.js'

export default {
  name: 'Sidebar',

  mixins: [menuMixin],

  props: {
    elevated: Boolean
  },

  components: {
    SidebarItem
  },

  computed: {
    ...mapGetters(['user']),
    sidebarTitle () {
      return this.getSidebarItems
        .find(item => this.$route.path
          .includes(item.path))
            ?.title
    },

    classes () {
      return {
        'sidebar--elevated': this.elevated
      }
    },

    getSidebarItems () {
      const currentRole = this.user.user.role
      return this.options.filter(item => item.allow.includes(currentRole))
    }
  }
}
</script>

<style lang="scss">
  @import '@/sass/abstracts/_variables';

  .sidebar {
    &--elevated {
      z-index: 100;
      width: $sidebar-max-width;
    }

    &__title {
      min-width: 18rem;
      margin-left: 2rem;
      font-size: 2rem;
      font-weight: 500;
    }

    &__list {
      padding: 2rem 0 2rem 1.6rem;
    }

    &__item {
      font-size: 1.4rem;
      line-height: 1.6rem;
      color: $color-black;
      border-right: .3rem solid transparent;

      &--active {
        border-right: .3rem solid $color-brand;

        .sidebar__link {
          color: $color-brand;
        }
      }
    }

    &__item:not(:last-child) {
      margin-bottom: 1.7rem;
    }

    &__link {
      display: flex;
      align-items: center;
      padding: .8rem 0;
      font-size: 1.4rem;
      line-height: 1.6rem;
      font-weight: 600;
      color: $color-black;
      transition: .4s ease;

      &:hover,
      &:active,
      &:focus {
        color: $color-brand;
      }
    }

    &__link-text {
      margin-left: 1.5rem;
    }
  }
</style>
